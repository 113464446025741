<template>
  <v-container>
    <v-row>
      <v-col
          sm="4"
          v-for="product in products" :key="product.id">
        <product-card :product="product"></product-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductCard from "@/components/ProductCard";

export default {
  name: "Related",
  components: {ProductCard},
  data() {
    return {
      products: [],
    }
  },
  mounted() {
    window.axios.get(
        '/api/related-products/' + this.$route.params.id + '?type=' + this.$route.params.type
    ).then((response) => {
      this.products = response.data.data
    })
  },
}
</script>

<style scoped>

</style>